import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import WhatsAppForm from "./../../Elements/WhatsAppForm";
var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-tb80 inner-page-padding back-ground-color">
          {/* LOCATION BLOCK*/}
          <div className="container">
            {/* GOOGLE MAP & CONTACT FORM */}
            <div className="section-content">
              {/* CONTACT FORM*/}
              <div className="row sfgbfb0909">
                {/* ABOUT COMPANY */}

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="inline-links contact-us1">
                    <h5 className="widget-title">
                      <img
                        src={require("./../../../images/flag-50.png")}
                        className="county-logo"
                        alt="Inteshape"
                      />{" "}
                      India Office
                    </h5>
                    <ul className="widget_address">
                      <li>
                        Electronic City, G-90, Sector-63, Noida-201301 (Delhi /
                        NCR)
                      </li>
                      <li>
                        <a href="tel:+91-8800384880" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <g fill="currentColor">
                              <path
                                fill-rule="evenodd"
                                d="m16.1 13.359l.456-.453c.63-.626 1.611-.755 2.417-.317l1.91 1.039c1.227.667 1.498 2.302.539 3.255l-1.42 1.412c-.362.36-.81.622-1.326.67c-1.192.111-3.645.051-6.539-1.643l3.964-3.963Zm-5.91-5.876l.287-.286c.707-.702.774-1.83.157-2.654L9.374 2.86C8.61 1.84 7.135 1.705 6.26 2.575l-1.57 1.56c-.433.432-.723.99-.688 1.61c.065 1.14.453 3.22 2.149 5.776l4.039-4.038Z"
                                clip-rule="evenodd"
                              />
                              <path
                                d="M12.063 11.497c-2.946-2.929-1.88-4.008-1.873-4.015l-4.039 4.04c.667 1.004 1.535 2.081 2.664 3.204c1.14 1.134 2.26 1.975 3.322 2.596L16.1 13.36s-1.082 1.076-4.037-1.862Z"
                                opacity=".6"
                              />
                            </g>
                          </svg>{" "}
                          (+91)-8800384880
                        </a>
                      </li>
                    </ul>
                    <div className="cn097">
                      {/* <NavLink
                        to={"https://maps.app.goo.gl/EbMMorqxAvW3ExwV8"}
                        target="_blank"
                      >
                        View Map
                      </NavLink> */}
                      <a
                        href="https://maps.app.goo.gl/EbMMorqxAvW3ExwV8"
                        target="_blank"
                      >
                        View Map
                      </a>
                    </div>
                  </div>
                </div>
                {/* USEFUL LINKS */}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="inline-links contact-us1">
                    <h5 className="widget-title">
                      <img
                        src={require("./../../../images/usa-50.png")}
                        className="county-logo"
                        alt="Inteshape"
                      />{" "}
                      USA Office
                    </h5>
                    <ul className="widget_address">
                      <li>
                        10104 Des Moines ST, Parker CO
                        80134
                      </li>
                      <li>
                        <a href="tel:+1-720-384-8706" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <g fill="currentColor">
                              <path
                                fill-rule="evenodd"
                                d="m16.1 13.359l.456-.453c.63-.626 1.611-.755 2.417-.317l1.91 1.039c1.227.667 1.498 2.302.539 3.255l-1.42 1.412c-.362.36-.81.622-1.326.67c-1.192.111-3.645.051-6.539-1.643l3.964-3.963Zm-5.91-5.876l.287-.286c.707-.702.774-1.83.157-2.654L9.374 2.86C8.61 1.84 7.135 1.705 6.26 2.575l-1.57 1.56c-.433.432-.723.99-.688 1.61c.065 1.14.453 3.22 2.149 5.776l4.039-4.038Z"
                                clip-rule="evenodd"
                              />
                              <path
                                d="M12.063 11.497c-2.946-2.929-1.88-4.008-1.873-4.015l-4.039 4.04c.667 1.004 1.535 2.081 2.664 3.204c1.14 1.134 2.26 1.975 3.322 2.596L16.1 13.36s-1.082 1.076-4.037-1.862Z"
                                opacity=".6"
                              />
                            </g>
                          </svg>{" "}
                          (+1)-720-384-8706
                        </a>
                      </li>
                    </ul>
                    <div className="cn097">
                      {/* <NavLink
                        to={"https://maps.app.goo.gl/E1j8mZQKBQPdZyst7"}
                        target="_blank"
                      >
                        View Map
                      </NavLink> */}
                      <a
                        href="https://maps.app.goo.gl/E1j8mZQKBQPdZyst7"
                        target="_blank"
                      >
                        View Map
                      </a>
                    </div>
                  </div>
                </div>
                {/* USEFUL LINKS */}
              </div>
              <div className="row sfgbfb0909">
                {/* ABOUT COMPANY */}

                {/* USEFUL LINKS */}
                <div className="col-lg-12 col-md-6 col-sm-6">
                  <div className="contact123 contact-us1">
                    <div className="row">
                      <div className="col-sm-6">
                        <h4>Get in touch</h4>
                        <div className=" widget_services inline-links contact1234">
                          <ul className="widget_address contact-details">
                            <h6>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                              >
                                <g fill="currentColor">
                                  <path
                                    fill-rule="evenodd"
                                    d="m16.1 13.359l.456-.453c.63-.626 1.611-.755 2.417-.317l1.91 1.039c1.227.667 1.498 2.302.539 3.255l-1.42 1.412c-.362.36-.81.622-1.326.67c-1.192.111-3.645.051-6.539-1.643l3.964-3.963Zm-5.91-5.876l.287-.286c.707-.702.774-1.83.157-2.654L9.374 2.86C8.61 1.84 7.135 1.705 6.26 2.575l-1.57 1.56c-.433.432-.723.99-.688 1.61c.065 1.14.453 3.22 2.149 5.776l4.039-4.038Z"
                                    clip-rule="evenodd"
                                  />
                                  <path
                                    d="M12.063 11.497c-2.946-2.929-1.88-4.008-1.873-4.015l-4.039 4.04c.667 1.004 1.535 2.081 2.664 3.204c1.14 1.134 2.26 1.975 3.322 2.596L16.1 13.36s-1.082 1.076-4.037-1.862Z"
                                    opacity=".6"
                                  />
                                </g>
                              </svg>{" "}
                              Get Connected
                            </h6>
                            <li>
                              <a href="tel:+91-88003 84880">+91-88003 84880</a>
                            </li>
                            <li>
                              <a href="tel:+91-8285560008">+91-8285560008</a>
                            </li>
                            {/*<li>
                              <a href="tel:+91-120 426 8670">
                                +91-120 426 8670
                              </a>
                            </li>*/}
                          </ul>
                        </div>
                        <div className=" widget_services inline-links contact1234">
                          <ul className="widget_address contact-details">
                            <h6>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4l217.6 163.2c11.4 8.5 27 8.5 38.4 0l217.6-163.2c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176v208c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V176L294.4 339.2a63.9 63.9 0 0 1-76.8 0L0 176z"
                                />
                              </svg>{" "}
                              Start & Grow Your Business With Us
                            </h6>
                            <li>
                              <a href="mailto:info@shadowinfosystem.com">
                                info@shadowinfosystem.com
                              </a>
                            </li>
                            <li>
                              <a href="mailto:sales@shadowinfosystem.com">
                                sales@shadowinfosystem.com
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <div class="card mb-2">
                              <div class="card-content">
                                <div class="card-body">
                                  <div class="media d-block">
                                    <div class="media-body text-center">
                                      <div className="sub-heading">15+</div>
                                      <div class="align-self-center">
                                        Years Of Experience
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div class="card mb-2">
                              <div class="card-content">
                                <div class="card-body">
                                  <div class="media d-block">
                                    <div class="media-body text-center">
                                      <div className="sub-heading">1500+</div>
                                      <div class="align-self-center">
                                        Secure Clients
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div class="card mb-2">
                              <div class="card-content">
                                <div class="card-body">
                                  <div class="media d-block">
                                    <div class="media-body text-center">
                                      <div className="sub-heading">4000+</div>
                                      <div class="align-self-center">
                                        Projects Delivered
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div class="card mb-2">
                              <div class="card-content">
                                <div class="card-body">
                                  <div class="media d-block">
                                    <div class="media-body text-center">
                                      <div className="sub-heading">16+</div>
                                      <div class="align-self-center">
                                        Excellence Awards
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <div className="conatc-forun ">
                          <div className="cdgbdv">
                            <div
                              id="crmWebToEntityForm  "
                              class="zcwf_lblTopBottom crmWebToEntityForm dsdwd123121">
                             <WhatsAppForm/>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* USEFUL LINKS */}
              </div>
              <div className="row">
                {/* ABOUT COMPANY */}

                <div className="col-lg-6 col-md-6 col-sm-6"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
